const orderApi = '/order-service/rest/api/external';
const paymentsApi = '/payment-service/api/external';

export const deferredPaymentsContext = {
  all: `${orderApi}/creditApplications`,
  allPostponements: `${orderApi}/postponementApplications`,
  allFactoring: `${orderApi}/factoringApplications`,
  postponementById: `${orderApi}/postponementApplications/{id}`,
  factoringById: `${orderApi}/factoringApplications/{id}`,
  addAttachment: `${orderApi}//creditApplications/attachments`,
  byId: `${orderApi}/creditApplications/{id}`,
  operatorDecision: `${orderApi}/creditApplications/{id}/operatorDecision`,
  sellerDecision: `${orderApi}/postponementApplications/{id}/sellerDecision`,
  sign: `${orderApi}/creditApplications/{id}/sign`,
  sellerSign: `${orderApi}/postponementApplications/{id}/sign`,
  status: `${orderApi}/creditApplications/{id}/status`,
  messages: `${orderApi}/creditApplications/{id}/messages`,
  loanById: `${paymentsApi}/credit/offers/{id}`,
  allLoans: `${paymentsApi}/credit/offers`,
  postponementApplicationById: `${paymentsApi}/postponement/offers/{id}`,
  factoringApplicationById: `${paymentsApi}/factoring/offers/{id}`,
  allFactoringApplications: `${paymentsApi}/factoring/offers`,
  allPostponementApplications: `${paymentsApi}/postponement/offers`,
  factoringSign: `${orderApi}/factoringApplications/{id}/sign`,
  factoringOperatorDecision: `${orderApi}/factoringApplications/{id}/operatorDecision`,
  factoringSellerDecision: `${orderApi}/factoringApplications/{id}/sellerDecision`,
  factoringChangeSum: `${orderApi}/factoringApplications/{id}/requestedSum`,
  factoringConfirmFund: `${orderApi}/factoringApplications/{id}/fund`,
};
